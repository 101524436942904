import _ from 'lodash/fp'

export const SERVER_ERROR = 'The communication with the server has failed, please check your connection and try again'

const onEmpty = (success,errors) => Object.keys(errors).length ? errors : success

export const required = (s, k) => !s && 'Required field(s).'

export const minLength = (n) => (s, k) => s && s.length < n && `Field must have at least ${n} characters.`

export const confirmPasswords = (passwordKey) => (confirm, k, props) => (props[passwordKey] || confirm) && props[passwordKey] !== confirm && 'Passwords do not match.'
export const confirmEmails = (emailKey) => (confirm, k, props) => (props[emailKey] || confirm) && props[emailKey] !== confirm && 'Emails do not match.'

const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
export const emailValidation = (s, k) => !emailRegex.test(s) && 'Invalid email address.'

export const buildValidation = (validations) => (props) =>
  onEmpty(false,
    _.flow(
      _.entries,
      _.map(([k,v]) => [k,[].concat(v).map(fn => fn(props[k], k, props)).filter(_ => _)]),
      _.filter(([k,v]) => v.length),
      _.fromPairs
    )(validations)
  )


export const responseFailure = (fn) => (_,response,{setErrors}) => {
  const errorSetter = setErrors
  const error = fn(...response)
  if (error === true) {
  } else if (error) {
    errorSetter(error)
  } else {
    errorSetter({"":[SERVER_ERROR]})
  }
}
